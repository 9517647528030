<template>
  <v-app :class="{ 'authenticated-layout': authenticatedLayout }">
    <!-- <AppInstallPrompt v-if="publicLayout" /> -->

    <template v-if="loadSessionComponents">
      <AppBar />
      <AppRefreshPrompt />
      <AppSnackbar />
      <AppDrawer />
      <CompanySheet />
      <FullscreenDialog />
      <ModalDialog />
      <WorkflowBuilder />
      <QuickActions />
    </template>

    <v-main>
      <div :class="dynamicClassNames" id="core-wrapper">
        <AppPageHeader v-if="loadSessionComponents" />

        <router-view :key="$route.fullPath"></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AppConfig from "@src/app.config"
import { mapGetters } from "vuex"
import { addTabVisibilityListener } from "@mixins/browser-event-listeners"
import { ContextViewStates } from "@mixins/app-contexts"

export default {
  name: "App",
  page() {
    return {
      title: AppConfig.title,
      titleTemplate(title) {
        title = typeof title === "function" ? title(this.$store) : title
        return title ? title : AppConfig.title
      },
      meta: [
        {
          name: "description",
          vmid: "description",
          content: AppConfig.description,
        },
        {
          property: "og:title",
          vmid: "og:title",
          content: "Nucla",
        },
        {
          property: "og:description",
          vmid: "og:description",
          content: AppConfig.description,
        },
        {
          property: "og:url",
          vmid: "og:url",
          content: `${window.location.protocol}//${window.location.host}${this.$route.path}`,
        },
        {
          property: "og:image",
          vmid: "og:image",
          content: require("@images/nucla/nucla-logo-light.png"),
        },
        {
          property: "og:type",
          vmid: "og:type",
          content: "website",
        },
        {
          property: "twitter:card",
          vmid: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:domain",
          vmid: "twitter:domain",
          content: window.location.host,
        },
        {
          property: "twitter:url",
          vmid: "twitter:url",
          content: `${window.location.protocol}//${window.location.host}${this.$route.path}`,
        },
        {
          property: "twitter:title",
          vmid: "twitter:title",
          content: "Nucla",
        },
        {
          property: "twitter:description",
          vmid: "twitter:description",
          content: AppConfig.description,
        },
        {
          property: "twitter:image",
          vmid: "twitter:image",
          content: require("@images/nucla/nucla-logo-light.png"),
        },
        {
          name: "robots",
          vmid: "robots",
          content: "index,follow",
        },
      ],
    }
  },
  components: {
    AppBar: () => import("@components/core/app/app-bar"),
    AppPageHeader: () => import("@components/core/app/app-page-header"),
    AppRefreshPrompt: () => import("@components/core/app/app-refresh-prompt"),
    AppSnackbar: () => import("@components/core/app/app-snackbar"),
    AppDrawer: () => import("@components/core/app/app-drawer"),
    CompanySheet: () => import("@components/core/company-sheet"),
    FullscreenDialog: () => import("@components/dialogs/fullscreen-dialog"),
    ModalDialog: () => import("@components/dialogs/modal-dialog"),
    WorkflowBuilder: () => import("@components/core/workflow-builder"),
    QuickActions: () => import("@components/core/quick-actions/wrapper"),
  },
  computed: {
    ...mapGetters({
      sessionObject: "auth/sessionObject",
      privacyConsentDate: "auth/privacyConsentDate",
      appInitLoad: "auth/appInitLoad",
      shareToken: "auth/shareToken",
      activeBrowser: "browser/activeBrowser",
    }),
    publicLayout() {
      return (
        (!this.appInitLoad && !this.shareToken && !this.sessionObject) ||
        (!!this.sessionObject && !this.privacyConsentDate)
      )
    },
    authenticatedLayout() {
      return (
        (!!this.sessionObject &&
          !!this.privacyConsentDate &&
          !this.isUserCreateForm) ||
        !!this.shareToken
      )
    },
    loadSessionComponents() {
      return (
        (!!this.sessionObject || !!this.shareToken) && !this.isUserCreateForm
      )
    },
    activeComponent() {
      let cvType = _.get(this.$route, "params.context_view.context_type", null)
      return !_.isNumber(cvType)
        ? null
        : ContextViewStates.CONTEXT_NAMES[cvType]
    },
    dynamicClassNames() {
      let base = ""

      if (this.shareToken) {
        base += " share-token"
      }

      if (this.activeComponent) {
        base += ` cv-${_.kebabCase(this.activeComponent)}`
      }

      if (this.activeBrowser) {
        base += ` ${this.activeBrowser}`
      }

      return base
    },
    isUserCreateForm() {
      return ["invite-user", "sso-new-user"].includes(
        _.get(this, "$route.name")
      )
    },
  },
  mounted() {
    addTabVisibilityListener()
    this.$store.dispatch("auth/getCsrfToken")
    this.setVhUnit()
    window.addEventListener("resize", this.handleResize)
  },
  beforeDestroy() {
    this.$store.dispatch("analytics/processQueue")
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    handleResize() {
      this.setVhUnit()
      this.$store.dispatch("app/setAppWidth")
    },
    setVhUnit() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/styles/_base.scss";
@import "./src/assets/styles/_overrides.scss";
@import "./src/assets/styles/_unauthed_container.scss";
</style>
